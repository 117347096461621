/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import "@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

/**
 * Ionic Dark Mode
 * -----------------------------------------------------
 * For more info, please see:
 * https://ionicframework.com/docs/theming/dark-mode
 */


@import "@ionic/angular/css/palettes/dark.system.css";
@font-face {
    font-family: 'Inter';
    src: url('./assets/fonts/Inter-Regular.ttf') format('truetype');   
    font-style: normal;
}

body {
  background-color: white; 
  color: black;
  font-family: 'Inter', sans-serif;
  // Sets the background color for the entire app
}

@media (prefers-color-scheme: dark) {
  body {
    --ion-background-color: white; 
    --ion-text-color: black; // Ensures the background is white in dark mode
    --ion-font-family: "Inter", sans-serif;
    --ion-item-background: white !important;
    --ion-color-primary-contrast:  #256AFE;    
    --ion-item-border-color: white !important;
    --ion-color-base:#256AFE !important;
    --ion-color-primary:#256AFE !important;
  }
}

/* Global Styles */
:root {
  --text-color: #000;
  --primary-bg-color: #256AFE;
  --inactive-button-color: white;
  --border-color: #D9D9D9;
  --button-spacing: 2rem;
  --height-sm: 600px;
  --height-md: 740px;
  --height-lg: 896px;
  --height-xl: 1024px;
  --height-xxl: 1366px;
  --ion-item-background: white !important;
  --ion-item-border-color: white !important;
  --ion-color-base:#256AFE !important;
  --ion-color-primary:#256AFE !important;
}

.bottom-row {
  margin-top: auto; /* Pushes the row to the bottom */  
}

.bottom-row-items {
  display: flex; /* Ensure contents are centered */
  flex-direction: column;
  align-items: center; /* Center items horizontally */
  justify-content: center; /* Center items vertically */
  margin-top: auto; /* Pushes the row to the bottom */
  width: 100%; /* Ensure the row takes the full width of the container */
}

.page-container {
  padding: 1rem; /* Responsive padding */
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ensures the container takes up at least the full viewport height */
  justify-content: space-between; /* Distributes space between children */   
  box-sizing: border-box; /* Ensure padding is included in the total width and height */
  width: 100vw; /* Ensure the grid takes full width of the viewport */
  max-width: 100vw;
}

a.sign-in-link, a.sign-up-link, .navigation-link {
  color: var(--primary-bg-color);
  text-decoration: none;
  cursor: pointer;  
}

/* Backdrop for dimming the page */
.dimming-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3); /* Dim effect with 30% opacity */
  z-index: 9; 
}

.form-bottom-row {
  text-align: left;
  width: 100%;
  margin-top: auto; /* Pushes it to the bottom of the page */
  display: flex;
  flex-direction: column;
}


.password-container {
    position: relative;  
    width: 100%; /* Ensure it takes full width of the parent container */
}

.password-toggle-icon {
    position: absolute;
    top: 50%;
    right: 2vw; /* Use viewport width to keep it inside the input */
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 2;
    padding: 0 2vw; /* Adjust padding to ensure it stays inside the input */
}

.sub-heading {
    margin-top: 1rem; /* Responsive margin-top */
    margin-bottom: 0.3rem;
    width: 100%; /* Ensure it takes full width of the parent container */
}

.app-date-time-input {
  width: 100%; /* Make inputs full width of their container */
  margin-bottom: clamp(1rem, 1.5vh, 2rem); /* Responsive margin-bottom for inputs */
}

.use-pin-link {
  display: block; /* Ensures the link is treated as a block-level element */
  margin: 2rem auto;
  color: #256AFE;  
  cursor: pointer;
  text-align: center;
  font-size: 1rem;
  line-height: 1.375rem;
  font-weight: 500;
}

.error-message {
  text-align: center;
  margin-top: clamp(1rem, 2.5vh, 2.5rem); /* Responsive margin-top */
}

.forgot-password-link {
  display: block;
  text-align: right;
  color: #256AFE;
  margin-top: 1rem; /* Converted 5px to rem for consistency */
  cursor: pointer;
  font-weight: 400;
  font-size: clamp(1rem, 1vw + 0.25rem, 1.5rem); /* Adjust font size based on viewport */
  line-height: clamp(1rem, 1.25rem, 1.5rem); /* Adjust line height based on viewport */
}

/* Ion Content Styles */
ion-content {
  display: flex;
  flex-direction: column;
  flex: 1;               /* Allow ion-content to expand and take available space */
}

/* Footer Styles */
.footer { 
  position: fixed;
  bottom: 0;          /* Distance from the bottom of the viewport */
  left: 0;
  width: 100vw;         /* Full viewport width */
  background-color: #FFFFFF; /* Ensure the footer has a background */
  padding: 1rem;        /* Padding inside the footer */
}

.custom-popover-class {
  --min-width: 10rem; /* Adjusted minimum width */
  --max-width: 12rem; /* Adjusted maximum width */
  border-radius: 0.75rem !important; /* Rounded corners */
  box-shadow: 0rem 0.25rem 0.9375rem 0rem #00000026; /* Subtle shadow */
  margin-left: -2rem; /* Display popover to the left of the icon */
  overflow: hidden;
}

.custom-popover-class .popover-list {
  padding: 0;
  margin: 0;
  background: white;
  border-radius: 0.75rem !important; /* Rounded corners */
}

.custom-popover-class .popover-item {
  --background: transparent;
  --min-height: 2rem;
  font-size: 0.875rem;
  color: black;
  border-bottom: 0.063rem solid #F7F7F7;
  padding: 0.5rem 0.5rem; /* Adjusted padding to remove excess space */
  text-align: left; /* Align text to the left */
  --ripple-color: rgba(0, 0, 0, 0.1);
}

.custom-popover-class .popover-item:last-child {
  border-bottom: none; /* Remove border for the last item */
}

.custom-popover-class .popover-item:hover {
  background-color: #f2f2f2; /* Hover effect */
}

ion-list {
  border-radius: 0.75rem !important;
}

ion-button .button-inner {
  letter-spacing: 0.05rem;
}